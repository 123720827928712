import store from "@/store";
import dayjs from "dayjs";
import { User } from "@/models";
import { utils } from "@socotec.io/socio-aos-component";
import { pingHealthCheck } from "@/utils/healthCheck";
import i18n from "@/setup/i18n";
import RxDB from "@/rxdb/utils";
import { useRxdbCollection } from "@/composables/useRxdbCollection";

const initOfflineMode = async () => {
  const { execOnCollection: userCollection } = useRxdbCollection("user");
  const user = await userCollection((c) =>
    c
      .findOne({
        selector: {
          isCurrentUser: true,
        },
      })
      .exec()
  );

  const weeks_validity =
    parseInt(process.env.VUE_APP_OFFLINE_WEEKS_VALIDITY) || 6;
  const endValidityDate = dayjs().subtract(weeks_validity, "week");
  const lastLoginDate = dayjs(user?.lastLoginDate);
  if (lastLoginDate > endValidityDate && user) {
    User.insert({ data: JSON.parse(JSON.stringify(user)) });
  } else {
    throw Error("Unauthorized access to offline mode");
  }
};

const isLoggedOrOffline = async (to, from, next) => {
  await RxDB.initializeRxDB();
  // INFO/HACK - MS - 25/04/24 - if not utils.setRxDBInstance(instance)
  // the rxdb db accessed by socio-aos-component in his compiled file (es.js) is empty of collections
  const instance = RxDB.getInstance();
  utils.setRxDBInstance(instance);
  store.commit("SET_APP_IS_LOADING", true);
  let isOnline = store.state.appIsOnline;
  try {
    await pingHealthCheck();
    store.commit("SET_APP_ONLINE", true);
  } catch (error) {
    console.error(error);
    store.commit("SET_APP_ONLINE", false);
    isOnline = false;
    store.dispatch(
      "notifications/showWarningNotification",
      i18n.t("offline.networkError")
    );
  }
  if (isOnline) {
    await store.dispatch("user/initializeCurrentUser");
  } else {
    try {
      await initOfflineMode();
    } catch {
      return next({ name: "unauthorized-access-offline-mode" });
    }
  }
  store.commit("SET_APP_IS_LOADING", false);
  next();
};

const isOffline = (from, to, next) => {
  if (store.state.appIsOnline) {
    next();
  } else {
    next("/");
  }
};

export { isLoggedOrOffline, isOffline };
