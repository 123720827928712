import { BaseAdminGroupModel } from "./baseModels";
import Priority from "./Priority";
import PriorityGroupPriorityPivot from "./PriorityGroupPriorityPivot";

export default class PriorityGroup extends BaseAdminGroupModel {
  static name = "PriorityGroup";

  static entity = "priorityGroups";

  static fields() {
    return {
      ...super.fields(),
      reportLabel: this.attr(),
      prioritiesData: this.belongsToMany(
        Priority,
        PriorityGroupPriorityPivot,
        "priorityGroupUuid",
        "priorityUuid"
      ),
      priorities: this.attr([]),
    };
  }

  get itemsList() {
    return this.priorities;
  }
}
