import BuildingAsset from "./BuildingAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { BaseAssetModel } from "./baseAssetModel";
import { TagThrough } from "./AssetTag";

export class SiteTag extends TagThrough {
  static name = "SiteTag";
  static entity = "siteTags";
  static relatedModel = () => SiteAsset;
}

export default class SiteAsset extends BaseAssetModel {
  static name = "SiteAsset";
  static entity = "siteAssets";
  static aosModel = () => AosVuexOrmModel["AosSite"];
  static listAction = "site/fetchSites";

  static fields() {
    return {
      ...super.fields(),
      code: this.string(""),
      buildings: this.hasMany(BuildingAsset, "site"),
      tags: this.attr([]),
      tagsData: this.hasManyBy(SiteTag, "tags"),
    };
  }
}
