import { BaseModel } from "./baseModels";
import ProjectConfigAnalyticalAxis from "./ProjectConfigAnalyticalAxis";

export default class AnalyticalAxis extends BaseModel {
  static name = "AnalyticalAxis";

  static entity = "analyticalAxes";

  static state() {
    return {
      codeToUuidMap: {},
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),
      label: this.string(""),
      shortLabel: this.attr(""),
      isArchived: this.attr(false),
      code: this.string(""),
      ordering: this.number(0),
      description: this.attr(""),
      missionObject: this.attr(""),
      missionObjective: this.attr(""),
      referenceText: this.attr(""),
      projectConfigAnalyticalAxes: this.hasMany(
        ProjectConfigAnalyticalAxis,
        "analyticalAxis"
      ),
    };
  }

  static afterCreate(model) {
    this.commit((state) => {
      state.codeToUuidMap[model.code] = model.uuid;
    });
  }
}
