import { BaseModel } from "./baseModels";
import store from "@/store";
import AosObservable from "./AosObservable";
import BIMDataPin from "./BIMDataPin";
import Action from "./Action";
import { makePerimeterFromAosObservables } from "@/utils/perimeterRepr";

export const SYNCHRONISATION_STATE = {
  UNKNOWN: "unknow",
  OFFLINE: "offline",
  SYNCED: "synced",
  ERROR: "error",
};
export default class Observation extends BaseModel {
  static name = "Observation";
  static entity = "observations";

  static fields() {
    return {
      ...super.fields(),
      createdBy: this.string(""),
      modifiedBy: this.string(""),
      origin: this.string(""),
      metadata: this.attr({}),
      ctComment: this.string(""),
      refCode: this.string(""),
      isValidated: this.boolean(false),
      perimeter: this.attr(null),
      isInSentReport: this.boolean(false),
      isArchived: this.boolean(false),

      // foreign keys
      aosObservablesList: this.attr([]),
      amosDocumentsList: this.attr([]),
      riskAssessment: this.attr(null),

      // Orm relation
      pin: this.hasOne(BIMDataPin, "observation"),

      // aggregates
      actionsCount: this.number(0),
      smallestQuotationValue: this.number(-1),
      smallestQuotationStatement: this.string(""),
      analyticalAxis: this.string(""),
      statementsCount: this.number(0),
      createdByEmail: this.string(""),
      modifiedByEmail: this.string(""),
      createdByFirstname: this.string(""),
      modifiedByFirstname: this.string(""),
      createdByLastname: this.string(""),
      modifiedByLastname: this.string(""),
      documentsUrlAndNames: this.attr([]),

      // reverse FK to referencial node models
      dispositionTypesList: this.attr([]),
      typologyTypesList: this.attr([]),
      ctNodesList: this.attr([]),

      functionalRequirementsList: this.attr([]),
      createdOrUpdatedOffline: this.attr(false),

      // necessary to create the assign query in observation replication push
      offlineRequestBuildMetaData: this.attr({}),
      dispositionUuids: this.attr([]),
      synchronisationState: this.string(SYNCHRONISATION_STATE.SYNCED),
    };
  }

  makePerimeter() {
    const { assets, pathsMapping } =
      this.$store().getters["asset/getAllAssets"];
    return makePerimeterFromAosObservables({
      aosObservablesList: this.aosObservablesList,
      assets,
      pathsMapping,
    });
  }

  static afterDelete(model) {
    Action.query()
      .where((action) => action.observationsList.includes(model.uuid))
      .get()
      .forEach((action) => {
        const updatedM2M = action.observationsList.filter(
          (obsUuid) => obsUuid !== model.uuid
        );

        action.observationsList = updatedM2M;
        action.$save();
      });
  }

  async setDocumentsData() {
    this.documentsUrlAndNames = await Promise.all(
      this.amosDocumentsList.map(async (docoumentUuid) =>
        store.dispatch("document/getDocumentUrlAndName", docoumentUuid)
      )
    );
  }

  get relatedAosItems() {
    return AosObservable.findIn(this.aosObservablesList).map(
      (pObs) => pObs.aosItemData
    );
  }

  get relatedObservableItems() {
    return AosObservable.findIn(this.aosObservablesList);
  }

  get documentsData() {
    return this.documentsUrlAndNames;
  }

  get ctObservationStatementCount() {
    if (!this.isValidated) return 0;
    return this.metadata.ct_observation?.object_type_statements?.length;
  }
}
