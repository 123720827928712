import { listAllObjects } from "./utils/listAllObjects.js";

import { useRxdbCollection } from "@/composables/useRxdbCollection.js";
import {
  convertMapToArray,
  makeSchemaCompliantIgnoringKeys,
} from "@/rxdb/utils.js";
import { cloneDeep } from "lodash";

export const useAosObservableReplication = (
  visitUuid,
  client,
  observableClient
) => {
  const { execOnCollection: perimeterCollection } =
    useRxdbCollection("user_perimeter");
  const { execOnCollection: aosObservableCollection } =
    useRxdbCollection("aos_observable");
  const { execOnCollection: componentAssetCollection } =
    useRxdbCollection("component_asset");
  const { execOnCollection: aosComponentCollection } =
    useRxdbCollection("aos_component");

  const getDeltaResult = async (uuidList) => {
    if (!uuidList.length) return [];

    const metadata = {
      pagination: JSON.stringify({ page_size: 500 }),
    };
    const response = await listAllObjects(
      observableClient,
      "list",
      { uuids: uuidList },
      metadata
    );
    return response;
  };

  const updateVisitPerimeterInRxdb = async (
    newObservableList,
    visitPerimeter
  ) => {
    let perimeterToUpdate;
    const perimeterMap = await perimeterCollection((c) =>
      c.findByIds([visitPerimeter.uuid]).exec()
    );
    perimeterToUpdate = perimeterMap.get(visitPerimeter.uuid);

    if (!perimeterToUpdate) perimeterToUpdate = cloneDeep(visitPerimeter);
    else perimeterToUpdate = perimeterToUpdate.toMutableJSON();

    for (const newObservable of newObservableList) {
      if (!perimeterToUpdate.aosObservableUuids)
        perimeterToUpdate.aosObservableUuids = [];

      perimeterToUpdate.aosObservableUuids.push(newObservable.uuid);
      if (!perimeterToUpdate.aosItemUuids) perimeterToUpdate.aosItemUuids = [];
      perimeterToUpdate.aosItemUuids.push(newObservable.aosItem);

      if (!perimeterToUpdate.assetUuidCollection)
        perimeterToUpdate.assetUuidCollection = {};

      if (!perimeterToUpdate.assetUuidCollection[newObservable.assetType])
        perimeterToUpdate.assetUuidCollection[newObservable.assetType] = [];
      perimeterToUpdate.assetUuidCollection[newObservable.assetType].push(
        newObservable.assetUuid
      );

      if (!perimeterToUpdate.aosItemUuidCollection)
        perimeterToUpdate.aosItemUuidCollection = {};
      if (!perimeterToUpdate.aosItemUuidCollection[newObservable.assetType])
        perimeterToUpdate.aosItemUuidCollection[newObservable.assetType] = [];
      perimeterToUpdate.aosItemUuidCollection[newObservable.assetType].push(
        newObservable.aosItem
      );
    }
    perimeterToUpdate.projectId = visitUuid;
    return makeSchemaCompliantIgnoringKeys(perimeterToUpdate, [
      "aosObservables",
      "source",
    ]);
  };

  const pullAosObservable = async () => {
    const visitPerimeter = await client.retrieveProjectPerimeter({
      projectUuid: visitUuid,
    });

    if (!visitPerimeter) throw new Error("No perimeter found for offline");

    let localVisitPerimeter = await perimeterCollection((c) =>
      c.findOne(visitPerimeter.uuid).exec()
    );

    localVisitPerimeter = localVisitPerimeter?.toJSON() || null;

    const aosObservableUuidsDelta = visitPerimeter.aosObservables.filter(
      (uuid) => !localVisitPerimeter?.aosObservableUuids?.includes(uuid)
    );

    const aosObservableDelta = await getDeltaResult(aosObservableUuidsDelta);

    const userPerimeter = await updateVisitPerimeterInRxdb(
      aosObservableDelta,
      visitPerimeter
    );
    return {
      aos_observable: aosObservableDelta,
      user_perimeter: [userPerimeter],
    };
  };

  const getLocalAosObservableChanges = async (
    aosObservableWithoutComponent,
    importedComponent
  ) => {
    let localObsersableToRemove = [];
    let observableWithChangedAosItemUuid = [];
    for (let observable of aosObservableWithoutComponent) {
      if (importedComponent.length) {
        const component = importedComponent.find(
          ({ oldOfflineUuid }) => oldOfflineUuid === observable.aosItem
        );
        if (component) {
          observable.aosItem = component.uuid;
          observableWithChangedAosItemUuid.push(observable);
        } else {
          localObsersableToRemove.push(observable.uuid);
        }
      } else {
        localObsersableToRemove.push(observable.uuid);
      }
    }
    return { localObsersableToRemove, observableWithChangedAosItemUuid };
  };

  const getAosObservableRequest = async (
    localObservableToCreate,
    perimeterToSync
  ) => {
    let request = {
      uuid: perimeterToSync.uuid,
      createdAt: perimeterToSync.created,
      updatedAt: perimeterToSync.updatedAt,
      isArchived: perimeterToSync.isArchived,
      source: perimeterToSync.source,
      aosObservables: [],
      assets: [],
    };

    for (const localAoscomposable of localObservableToCreate) {
      request.aosObservables.push(localAoscomposable);
    }

    let componentAssetToSync = await componentAssetCollection((c) =>
      c
        .findByIds(localObservableToCreate.map(({ assetUuid }) => assetUuid))
        .exec()
    );
    componentAssetToSync = convertMapToArray(componentAssetToSync).map((d) =>
      d.toJSON()
    );
    for (const componentAsset of componentAssetToSync) {
      request.assets.push({
        uuid: componentAsset.uuid,
        observationComment: componentAsset.observationComment,
        code: componentAsset.code,
      });
    }

    return request;
  };
  const getUniqueAosObservable = (
    aosObservableWithComponent,
    observableWithChangedAosItemUuid
  ) => {
    const uniqueAosItemUuid = new Set();
    const localObservableToCreate = [
      ...aosObservableWithComponent,
      ...observableWithChangedAosItemUuid,
    ].filter((doc) => {
      if (uniqueAosItemUuid.has(doc.aosItem)) {
        return false;
      }
      uniqueAosItemUuid.add(doc.aosItem);
      return true;
    });
    return localObservableToCreate;
  };
  const getDebugMetadata = (
    aosObservableToCreate,
    observableWithChangedAosItemUuid,
    localObsersableToRemove,
    successComponent,
    duplicateComponent
  ) => {
    let debugData = {};
    if (successComponent.length || duplicateComponent.length) {
      debugData.aosItem = {};
    }
    if (
      aosObservableToCreate.length ||
      observableWithChangedAosItemUuid ||
      localObsersableToRemove
    ) {
      debugData.localAosObservable = {};
    }
    for (const component of [...successComponent, ...duplicateComponent]) {
      debugData.aosItem[component.uuid] = {
        ...component,
      };
    }
    for (const aosObs of aosObservableToCreate) {
      debugData.localAosObservable[aosObs.uuid] = {
        ...aosObs,
        typeOfAosObs: "no-problem",
      };
    }
    for (const aosObs of observableWithChangedAosItemUuid) {
      debugData.localAosObservable[aosObs.uuid] = {
        ...aosObs,
        typeOfAosObs: "aosItem uuid was updated front-side",
      };
    }
    for (const aosObs of localObsersableToRemove) {
      debugData.localAosObservable[aosObs.uuid] = {
        ...aosObs,
        typeOfAosObs: "was delete front side",
      };
    }
    return { filters: JSON.stringify({ debug_data: debugData }) };
  };

  const pushAosObservable = async (document) => {
    const offlineObservable = document.filter(
      ({ createdOrUpdatedOffline }) => createdOrUpdatedOffline
    );
    if (!offlineObservable.length) {
      return {
        response: [],
        success: [],
        failure: [],
      }; // if empty, nothing to resyn
    }

    let perimeterToSync = await perimeterCollection((c) =>
      c
        .findOne({
          selector: {
            projectId: visitUuid,
            userId: null,
          },
        })
        .exec()
    );
    perimeterToSync = perimeterToSync.toJSON();
    const aosItemDependenciesUuidList = offlineObservable.map(
      ({ aosItem }) => aosItem
    );

    let componentCreated = convertMapToArray(
      await aosComponentCollection((c) =>
        c.findByIds(aosItemDependenciesUuidList).exec()
      )
    );
    componentCreated = componentCreated.map((d) => d.toJSON());
    const aosObservableWithComponent = offlineObservable.filter(({ aosItem }) =>
      componentCreated.map(({ uuid }) => uuid).includes(aosItem)
    );

    let aosObservableWithoutComponent = offlineObservable.filter(
      ({ aosItem }) =>
        !componentCreated.map(({ uuid }) => uuid).includes(aosItem)
    );

    let componentImported = aosObservableWithoutComponent.length
      ? await aosComponentCollection((c) =>
          c
            .find({
              selector: {
                oldOfflineUuid: {
                  $in: aosObservableWithoutComponent.map(
                    ({ aosItem }) => aosItem
                  ),
                },
              },
            })
            .exec()
        )
      : [];
    componentImported = componentImported.map((d) => d.toJSON());
    if (
      [...componentCreated, ...componentImported].some(
        ({ createdOrUpdatedOffline }) => createdOrUpdatedOffline
      )
    ) {
      throw new Error("Replication push: must wait resync");
    }
    const { localObsersableToRemove, observableWithChangedAosItemUuid } =
      await getLocalAosObservableChanges(
        aosObservableWithoutComponent,
        componentImported
      );

    const localObservableToCreate = getUniqueAosObservable(
      aosObservableWithComponent,
      observableWithChangedAosItemUuid
    );
    let response = [];
    if (localObservableToCreate.length) {
      const request = await getAosObservableRequest(
        localObservableToCreate,
        perimeterToSync
      );
      const metadata = getDebugMetadata(
        aosObservableWithComponent,
        observableWithChangedAosItemUuid,
        localObsersableToRemove,
        componentCreated,
        componentImported
      );

      response = await client.resyncPerimeter(request, metadata);
      debugLogResync(offlineObservable, response.results);
      try {
        const dataToInsert = localObservableToCreate.map((aosObservable) => ({
          ...aosObservable,
          createdOrUpdatedOffline: false,
        }));
        const keysToIgnore = ["deleted"];
        const copyData = makeSchemaCompliantIgnoringKeys(
          dataToInsert,
          keysToIgnore
        );
        await aosObservableCollection((c) => c.bulkUpsert(copyData));
      } catch (e) {
        console.error("Push AosObservable Error insering in rxdb", e);
      }
    }
    if (localObsersableToRemove.length)
      await aosComponentCollection((c) =>
        c.bulkRemove(localObsersableToRemove)
      );
    return {
      response: response.results ?? [],
      success: response.results
        .filter(({ status }) => status === "success")
        .map(({ uuid }) => uuid),
      failure: response.results
        .filter(({ status }) => status === "failure")
        .map(({ uuid }) => uuid),
    };
  };
  const debugLogResync = (aosObservableToSync, responseList) => {
    const mapDocByUuid = new Map(
      aosObservableToSync.map((observable) => [observable.uuid, observable])
    );
    let message = "\nJe suis le rapport coté amos !\n";
    const failureList = responseList.filter(
      ({ status }) => status === "failure"
    );
    const successList = responseList.filter(
      ({ status }) => status === "success"
    );
    message += successList.length
      ? `${successList.length} création de composent amos réussis\n`
      : "Aucune création réussi\n";
    for (const success of successList) {
      let concernedObservable = mapDocByUuid.get(success.uuid);
      message += `- Observablié lié a l'element aos : ${concernedObservable.aosItem} || uuid: ${concernedObservable.uuid} \n`;
    }
    message += failureList.length
      ? `${failureList.length} création de composent amos en ECHEC \n`
      : "Aucune création en échec\n";

    for (const failure of failureList) {
      let concernedObservable = mapDocByUuid.get(failure.uuid);
      message += `- Observablié lié a l'element aos : ${concernedObservable.aosItem} || uuid: ${concernedObservable.uuid} || reason: ${failure.reason}\n`;
    }
    console.log(message);
  };
  const insertVisitPerimeter = async (perimeter) => {
    const keysToIgnore = ["aosObservables", "source"];
    const copy = makeSchemaCompliantIgnoringKeys(perimeter, keysToIgnore);
    try {
      return await perimeterCollection((c) => c.upsert(copy));
    } catch (e) {
      console.error("Error insert perimeter in rxdb", e);
    }
  };

  return {
    pullAosObservable,
    pushAosObservable,
    insertVisitPerimeter,
  };
};
