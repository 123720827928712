import { BaseModel } from "./baseModels";

export default class TypologyType extends BaseModel {
  static name = "TypologyType";

  static entity = "typologyTypes";

  static state() {
    return {
      referencialUuidMap: {},
      referencialLabelUuidsMap: {},
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),

      referencialNodePath: this.string(""),
      referencialNodeLabel: this.string(""),
      referencialNodeUuid: this.string(""),
      referencialVersion: this.string(""),
      referencialNodeLevel: this.number(),

      aosComponentTypeUuid: this.string(""),
      regulatory: this.string(""),
      applicationCondition: this.string(""),
      apcriClassification: this.attr(),
      surveyRule: this.string(""),
      apcriClassificationLabel: this.string(""),
      surveyRuleLabel: this.string(""),
      isArchived: this.boolean(false),
      referencialNodeOriginalPath: this.string(""),
      description: this.string(""),

      // foreign keys
      analyticalAxis: this.string(""),
    };
  }

  // add the model to the referencialUuidMap
  static beforeCreate(model) {
    this.commit((state) => {
      state.referencialUuidMap[model.referencialNodeUuid] = model;
      state.referencialLabelUuidsMap[model.referencialNodeLabel] = (
        state.referencialLabelUuidsMap[model.referencialNodeLabel] || []
      ).concat(model.uuid);
    });
  }
}
